@use '../global-variables' as gv;

.loading-form-info {
  width: 100%;
  text-align: center;
}

#survey-container {
  margin: 10px auto;
  text-align: center;
  width: 80vw;
  height: fit-content;
  // height: calc(100vh - gv.$footer-height);

  iframe {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}