/*
 * Contains SCSS variables needed in files all over app
 */

$secondary-color: #e8f0ec;
$primary-color: #9cd394;
$bkg-color: white;
$dark-color: #2D2D2D;
$green: #457537;
$footer-height: 25vh;
$footer-mobile-height: 40vh;
$header-height: 10vh;
$default-resize-width: 767px;
$ticker-text-resize: 1256px;