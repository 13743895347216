@use '../global-variables' as gv;

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  //height: gv.$footer-height;
  height: fit-content;
  max-height: gv.$footer-height;
  @media (max-width: gv.$default-resize-width) {
    max-height: gv.$footer-mobile-height;
  }
  background-color: gv.$secondary-color;
  color: white;

  div.outer-div {
    width: 100%;
    position: relative;
    padding: 3%;
  }

  div.inner-div {
    margin: 0 auto;
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: row;
    @media (max-width: gv.$default-resize-width) {
      flex-direction: column;
    }
  }

  .ufei-container {
    width: fit-content;
  }

  p {
    color: #666;
  }

  .logo-container {
    width: 25%;
    @media (max-width: gv.$default-resize-width) {
      width:80%;
    }
    
    #calpoly-footer-logo {
      object-fit: scale-down;
      max-width: 100%;
      height:min-content;
      @media (max-width: gv.$default-resize-width) {
        padding: 5%;
      }
    }
  }

  #privacy-notice {
    color: #457537;
    text-decoration: none;
  }
}