@use '../global-variables' as gv;

main {
  background-color: gv.$bkg-color;
  text-align: center;
  color: gv.$dark-color;
}

.anchor {
  padding-top: calc(gv.$header-height + 0.5em);
  margin-top: calc(-1 * gv.$header-height);
}

.main-pg-section {
  margin: 2em 0;

  h1 {
    font-size: 2em;
  }
}