@use '../global-variables' as gv;

@font-face {
  font-family: 'Shizuru';
  src: url('/fonts/Shizuru-Regular.woff') format('woff'),
      url('/fonts/Shizuru-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.woff') format('woff'),
      url('/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$slideshow-height: 80vh;

.slideshow {
  // box-sizing: border-box;
  // max-width: 100%;
  width: 90%;
  height: $slideshow-height;
  position: relative;
  margin: auto;

  /* Container for slide image and text */
  .slide {
    width: 100%;
    height: $slideshow-height;

    display: none;
    font-size: 2vw;
    font-family: 'Open Sans', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: left;

    /* Background image */
    /* Image itself defined by Slideshow.tsx */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    /* Number text (1/3 etc) */
    .numbertext {
      padding: 8px 12px;
      position: absolute;
      top: 0;
      right: 0;

      color: #f2f2f2;
      font-size: 12px;
    }

    /* Slide label */
    .labelText {
      padding: 8px 10% 9px 25px;
      position: absolute;
      top: 3%;

      border-color: gv.$dark-color;
      border-width: 0;
      // border-left-width: 3px;
      box-shadow: inset 0 0 4px #000000;
      border-style: solid;
      background-color: gv.$bkg-color;

      color: gv.$dark-color;
      font-weight: 1000;
    }
    
    /* Mobile format changes */
    @media (max-width: gv.$default-resize-width) {
      font-size: 2.5vh;
    }
  }

  /* Container for navigation dots */
  .dotContainer {
    padding: 8px 12px;
    position: absolute;
    bottom: 5%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    /* The dots/bullets/indicators */
    .dot {
      cursor: pointer;
      height: 10px;
      width: 10px;
      margin: 0 32px;
      background-color: #FFF8;
      border-radius: 50%;
      transition: background-color 0.35s ease;
    }

    .dot:hover {
      background-color: #FFFD;
    }

    .active {
      background-color: #FFFF;
      height: 16px;
      width: 16px;
    }
  }

  /* Style for navigation arrows */
  .next, .prev {
    cursor: pointer;
    user-select: none;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    //padding: 1rem 3px 2rem 3px;
    padding: 0rem 1rem;

    color: white;
    font-family: 'Shizuru';
    font-size: 500%;
    font-weight: bold;
    text-align: center;
    //line-height: 0rem;

    transition: 0.4s ease;
  }

  .next {
    right: 0;
    border-radius: 3px 0px 0px 3px;
  }

  .prev {
    left: 0;
    border-radius: 0px 3px 3px 0px;
  }
  
  .next:hover, .prev:hover {
    background-color: #000C;
  }

  /* Fading animation */
  .ssAnim {
    animation-name: slideLeft;
    animation-duration: 1.5s;
  }

  @keyframes slideLeft {
    from    {width:0%} 
    to      {width:100%}
  }

}