@use '../global-variables' as gv;

header {
  background-color: gv.$bkg-color;
  filter: drop-shadow(0 0px 2.5px #6b6b6b);
  height: gv.$header-height;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;

  nav.full-nav-bar {
    color: gv.$dark-color;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-width: fit-content;
    width: 60%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 0;
  }
}

.nav-link-container {
  padding: 0 4%;
  // border-right: 3px solid #666;
  text-align: center;

  a {
    color: gv.$dark-color;
    text-decoration: none;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-decoration: none;
  }
}

.nav-link-container:last-child {
  border-right: 0 !important;
}

#ufei-logo-header-container {
  margin-left: 10%;
}

@media (max-width: gv.$ticker-text-resize) {
  header nav.full-nav-bar {
    font-size: 1em;
  }
}
