@use '../global-variables' as gv;

.MapContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
}

@media (max-width: gv.$default-resize-width) {
  .MapContent {
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
  }
}