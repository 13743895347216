.btnTree {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 5% 10%;
  width: 100%;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 2vw;
  text-decoration: none;
  cursor: pointer;
}