@use '../global-variables' as gv;

.plant-tree {
	//height: 100vh;
	vertical-align: middle;

	button {
		cursor: pointer;
		border: 0;
    // white-space: nowrap;
    background-color: gv.$green;
    color: gv.$bkg-color;
    padding: 1%;
    width: 15%;
    min-width: 150px;
    max-width: 15%;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 2vw;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-decoration: none;
		text-align: center;

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			word-wrap: break-word;
		}
	}

	button:active {
    background-color: #2e4e25;
	}

	.accordion-container {
		padding: 20px 10%;

		text-align: left;
		
		.accordion-menu {
			padding-bottom: 5px;
		}

		.accordion-label {
			background-color: #f5f5f5;
		}

		.accordion-content {
			padding: 0 10px;
		}
	}
}