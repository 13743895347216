@use '../global-variables' as gv;
@use '../global-styles.scss';

.counter-image {
  position: relative;
  margin-bottom: 2vh;
  background-image: url('./green-slo-450-darkened-3.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  height: 100vh;
  width: 100%;
}

.tree-counter-content {
  width: 100%;
  height: 100%;
}

.counter-welcome {
  color: white;
  width: 100%;
  text-align: left;

  h1 {
    margin: 0;
    padding-left: 5%;
    padding-top: 5%;
    font-size: 2em;
  }
}

.counter-holder {
  color: white;
  padding: 2% 7% 0 0;
  height: 100%;
  text-align: right;
}

.counter {
  font-size: 20vh;
  font-weight: 700;
  text-shadow: 2px 3px 2px black;
}

.counter-holder p {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}

.trees-planted-progress {
  position: absolute;
  left: 15%;
  top: 25%;
  width: 20vw;
  height: 20vh;

  p {
    color: white;
  }
}

.tree-action-btns-container {
  position: absolute;
  right: 7%;
  bottom: 15%;
  display: flex;
  flex-direction: column;
  height: 16%;
  justify-content: space-between;
  width: 15%;
  min-width: 150px;
  max-width: 15%;

  a.tree-action {
    @extend .btnTree;

    color: gv.$green;
    background-color: gv.$bkg-color;
    width: 100%;
    border-radius: 2vw;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 1);
  }
}

@media (max-width: gv.$default-resize-width) {
  .counter-welcome {
    text-align: center;
    padding-right: 5%;
    font-size: 3vw;
  }
  .counter {
    font-size: 10vh;
  }
  .counter-holder {
    padding: 10% 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .counter-holder p {
    font-size: 20px;
  }
  .tree-action-btns-container {
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;
    bottom: 10%;
  }
  .trees-planted-progress {
    left: 50%;
    transform: translateX(-50%);
    top: 34%;
    width: 30vw;
    height: 30vh;
  }
}