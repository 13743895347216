@use '../global-variables' as gv;

.standard-page {
  padding-bottom: calc(gv.$footer-height + 2vh);
  @media (max-width: gv.$default-resize-width) {
    padding-bottom: calc(gv.$footer-mobile-height + 2vh);
  }
  padding-top: gv.$header-height;
}

.standard-page-top-padding {
  padding-top: calc(gv.$header-height + 2vh);
}