.partners-slide-container {
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  left: 4%;
  letter-spacing: 0.15rem;
  font-weight: 700;
  text-align: center;
  width: 90%;

  color: #f2f2f2;

  .logos-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }

  .partner-anchor-wrapper {
    width: 100%;
  }
}