@use '../../global-variables' as gv;

$planted-dash-height: 83vh;

.card-holder {
  width: 30vw;
  display: grid;
  justify-items: center;
  gap: 3.5vh;
  align-content: flex-start;
}

.map-counter {
  font-size: 1em;
  font-weight: 700;
}

.map-number-of-trees {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.trees-planted-label {
  margin: 0;
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30vw;
}

.dashboard-outline {
  border: 0.35vw solid #2d2d2d;
  border-radius: 1vw;
  width: 35vw;
  min-height: $planted-dash-height;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planted-count {
  margin: 0.25em 0;
  font-weight: bold;
  font-size: 1.25em;
}

@media (max-width: gv.$default-resize-width) {
  .dashboard-outline {
    width: 80vw;
  }

  .card-holder {
    width: 100%;
    height: 100%;
  }

  .dashboard-header {
    width: 100%;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}