@use '../../global-variables' as gv;

/* Caption text */
.slideshow-basic-text {
  padding: 8px 12px;
  position: absolute;
  bottom: 10%;
  left: 2%;
  height: 27%;
  letter-spacing: 0.15rem;
  font-weight: 700;

  color: #f2f2f2;

  max-width: 75%;

  @media (max-width: gv.$default-resize-width) {
    max-width: 100%;
  }
}