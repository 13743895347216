@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-height: calc(100vh - 5px);
  position: relative;
  letter-spacing: 0.15rem;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
