@use '../../../global-styles.scss';
@use '../../../global-variables' as gv;

.main {
  margin: 5vh auto;
  text-align: center;

  h2 {
    margin-bottom: 5vh;
  }

  .signin {
    @extend .btnTree;

    background-color: gv.$green;
    color: gv.$bkg-color;
    padding: 2vh 3vw;
    width: fit-content;
    border-radius: 10px;
  }
}
